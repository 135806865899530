import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import MenuItems from "./menu_items";

interface SlideMenuProps {
  isOpen: boolean;
  onClose: Function;
}

const SlideMenu: React.FC<SlideMenuProps> = (props) => {
  return (
    <Drawer isOpen={props.isOpen} placement="right" onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent
        backgroundColor={COLORS_PRIMARY.SLIDE_MENU_BACKGROUND}
        paddingLeft="1rem"
      >
        <DrawerCloseButton
          onClick={() => props.onClose()}
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
        />
        <DrawerHeader color="#fcfcfc">Menu</DrawerHeader>

        <DrawerBody position="absolute" bottom="1rem">
          <MenuItems></MenuItems>
        </DrawerBody>

        <DrawerFooter></DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SlideMenu;
