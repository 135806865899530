import { Box, HStack, Image, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Logo from "../../images/logo.svg";
import MenuIcon from "../../images/header_menu_icon.svg";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { navigate } from "gatsby";
import SlideMenu from "./slide_menu";
import MenuItems from "./menu_items";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <HStack
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      padding="1rem"
      position="fixed"
      top="0"
      width="100%"
      left="0"
      justifyContent="space-between"
      zIndex="3"
    >
      <HStack onClick={() => navigate("/selection_screen")}>
        <Image src={Logo} />
      </HStack>

      {/* Desktop */}
      <Box display={{ base:"none", md:"flex" }} height="1rem">
        <MenuItems></MenuItems>
      </Box>

      {/* Mobile */}
      <Box
        display={{ base:"flex", md:"none" }}
        position="fixed"
        right="1.5rem"
        top=".5rem"
        width="2rem"
        height="2rem"
        onClick={() => onOpen()}
      >
        {" "}
        <Image width="100%" height="100%" src={MenuIcon} />
      </Box>

      <SlideMenu isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
};

export default Header;
