import { Flex, HStack, Image } from "@chakra-ui/react";
import React from "react";
import HomeIcon from "../../images/nav_images/home_nav_icon.svg";
import AboutIcon from "../../images/nav_images/about_nav_icon.svg";
import CommunityIcon from "../../images/nav_images/community_nav_icon.svg";
import InfluencerIcon from "../../images/nav_images/influencers_nav_icon.svg";
import NFTShopIcon from "../../images/nav_images/nft_shop_nav_icon.svg";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard } from "../shared/opalite_text";
import { navigate } from "gatsby";

const MenuItems: React.FC = () => {
  return (
    <Flex flexDirection={{ base:"column", md:"row" }}>
      <HStack onClick={() => navigate("/selection_screen")} marginTop=".5rem">
        <Image
          paddingRight={{ base:"1rem", md:".5rem" }}
          src={HomeIcon}
        />{" "}
        <OpaliteTextStandard
          paddingRight="1.5rem"
          fontSize={{ base:"1.25rem", md:".7rem" }}
          fontWeight="bold"
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
        >
          HOME
        </OpaliteTextStandard>
      </HStack>
      <HStack marginTop=".5rem">
        <Image
          paddingRight={{ base:"1rem", md:".5rem" }}
          src={CommunityIcon}
        />{" "}
        <OpaliteTextStandard
          paddingRight="1.5rem"
          fontSize={{ base:"1.25rem", md:".7rem" }}
          fontWeight="bold"
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
        >
          COMMUNITY
        </OpaliteTextStandard>
      </HStack>
      <HStack marginTop=".5rem">
        <Image
          paddingRight={{ base:"1rem", md:".5rem" }}
          src={AboutIcon}
        />{" "}
        <OpaliteTextStandard
          paddingRight="1.5rem"
          fontSize={{ base:"1.25rem", md:".7rem", }}
          fontWeight="bold"
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
        >
          ABOUT OPALITE
        </OpaliteTextStandard>
      </HStack>
      <HStack marginTop=".5rem">
        <Image
          paddingRight={{ base:"1rem", md:".5rem" }}
          src={NFTShopIcon}
        />{" "}
        <OpaliteTextStandard
          paddingRight="1.5rem"
          fontSize={{ base:"1.25rem", md:".7rem" }}
          fontWeight="bold"
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
          onClick={() => navigate("/all_nft")}
        >
          NFT SHOP
        </OpaliteTextStandard>
      </HStack>
      <HStack marginTop=".5rem">
        <Image
          paddingRight={{ base:"1rem", md:".5rem" }}
          src={InfluencerIcon}
        />{" "}
        <OpaliteTextStandard
          paddingRight="1.5rem"
          fontSize={{ base:"1.25rem", md:".7rem" }}
          fontWeight="bold"
          color={COLORS_PRIMARY.MENU_FONT_COLOR}
          onClick={() => navigate("/influencers")}
        >
          INFLUENCERS
        </OpaliteTextStandard>
      </HStack>
    </Flex>
  );
};

export default MenuItems;
